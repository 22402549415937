:root{
    --deepBlueColor: #3f4247;
}
audio { display:none;}

.req{
    color:red;
    font-weight: bold;
}

.step-active{
    color: blue;
    background: red;
    text-decoration: underline;
}

.Toastify {
    position: absolute;
    z-index: 999999;
}

.pointer{
    cursor: pointer;
}

::-webkit-scrollbar {
    width: 10px;
    height: 20px;
}
::-webkit-scrollbar-thumb {
    background: rgba(17, 15, 15, 0.3);
    border-radius: 2px;
}
::-webkit-scrollbar-track {
    background: transparent;
}
a:-webkit-any-link:focus {
    outline-offset: 1px;
}
a:hover{
	transition: all 0.3s ease-in-out 0s
}
a:before{
	transition: all 0.3s ease-in-out 0s
}

.align-rb{
    position: absolute;
    bottom: 10px;
    right: 10px;
}

.ReactModal__Overlay{
    z-index: 99999 !important;
}
.MuiPickersPopper-root{
    z-index: 99999 !important;
}

.clickable_text{
    color: red;
    text-decoration: underline;
    font-weight: bold;
    cursor: pointer;
}


p.staticsText {
    word-break: break-word;
    display: inline-block;
    font-size: 12px;
    padding: 0px 10px;
    margin: 0px;
    font-style: italic;
    color: #8a8f8f;
}

.historyDiv{
    position: fixed;
    right: -3000px;
    top: 0px;
    bottom: 0px;
    z-index: 999999;
    background: #f0fbfd;
    border-left: 1px solid #f3f3f3;
    padding: 10px !important;
    max-width: 350px !important;
    width: 100%;
    padding-top: 50px !important;
}
.showHistory{right:0px}
.historyDiv .card-body{padding: 0px;}
.trinClass *{transition: all ease-in-out .4s;}
.historyDiv .card > .MuiPaper-root.MuiPaper-elevation{max-height: 90vh !important;}
.headBtn{
    position: absolute;
    top: 8px;
}


/* mobile Menu Footer fixed */
.mobileMenuDesign{
    left: 0px;
    right: 0px;
    bottom:0px;
    background:var(--deepBlueColor);
    z-index: 999;
}
.IconsWithText{
    font-size:1.7rem;
    color:#fff;
}
.iconMenuText{
    font-size: 1.2rem;
    font-weight: 300;
    position: relative;
    top: -5px;
}

@media(max-width:575.5px){
    .iconMenuText {
        font-size: 1rem;
    }
}
@media(max-width:450.5px){
    .iconMenuText {
        font-size: .87rem;
    }
    .mobileMenuDesign .col{
        padding:0px 5px
    }
}

.IconsWithText a {color:#fff; text-decoration:none}

.IconsWithText a.active{color: #ff9500}

.count-box-select .card-body{
    background: #f7f7f7;
}

.red-dot {
    height: 8px;
    width: 8px;
    background-color: red;
    border-radius: 50%;
    display: inline-block;
    margin-left: 5px; /* Adjust spacing as needed */
    cursor: pointer;
}

/* 
.datepicker-top .rdtPicker {
    top: -250px !important; 
    bottom: auto !important;
} */


.scrollable-row {
    max-height: 500px; /* Adjust the height as needed */
    overflow-y: auto;
    padding-right: 15px; /* Prevent scrollbar overlap */
}

table {
    display: block;
    overflow-x: auto;
}