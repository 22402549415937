/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */


.login-7 h3 {
    margin: 0 0 35px;
    font-size: 23px;
    color: #040404;
    font-weight: 400;
}
.login-7 .form-section:before {
    content: "";
    width: 100%;
    height: 168px;
    position: absolute;
    top: 0;
    right: 0;
    background: url(../public/assets/images/img-55.png) top left repeat;
    background-size: cover;
    z-index: -1;
    border-radius: 30px 30px 0 0;
}
.login-7 .form-section {
    max-width: 500px;
    margin: 0 auto;
    width: 100%;
    background: #fff;
    padding: 50px;
    border-radius: 30px;
    box-shadow: 0 0 35px rgb(0 0 0 / 10%);
    text-align: left;
    position: relative;
    z-index: 0;
}
.login-7 .form-section .form-check-input {
    width: 18px;
    height: 18px;
    margin-top: 1px;
    position: absolute;
    border: 2px solid #bdbdbd;
    border-radius: 0;
    background-color: #fff;
    margin-left: -22px;
}
.login-7 {
    top: 0;
    width: 100%;
    bottom: 0;
    opacity: 1;
    z-index: 999;
    min-height: 100vh;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px 0;
}
#root .login-7{background-image: linear-gradient(#017ede, #c3f6ff);}
.login-7 .logo a img{max-width: 260px;}


@media(max-width:767px){
    .login-7 .form-section{padding: 20px;}
}